import { useEffect, useRef, useState } from "react";
import { saveToS3, saveToS3WithFileName, saveToS3WithFileNameWithoutTimestamp } from "../../resources/s3.resource";
import { supabase } from "../../lib/supabase";
import { handleChangeGameType } from "../../handlers/developers";
import JSZip from "jszip";

const useSaveSubmit = ({
	info,
	assets,
	policies,
	gameVersions: { v, versions, gameId, intVersion, updateVersion },
	media,
	setWaitingForApproval,
}) => {
	const [saveEnabled, setSaveEnabled] = useState(false);
	const saveEnabledRef = useRef();
	const [submitEnabled, setSubmitEnabled] = useState(false);
	const [testEnabled, setTestEnabled] = useState(false);
	const [saving, setSaving] = useState(false);
	const savingRef = useRef();

	// Prevent user from leaving the page with unsaved changes
	useEffect(() => {
		//this function and the event listeners prevent the user from leaving the page with unsaved changes
		const preventLeavingUnsavedChanges = (event) => {
			if (saveEnabledRef.current || savingRef.current) {
				event.preventDefault();
				event.returnValue = ""; // For legacy browsers
				return ""; // For modern browsers
			}
		};
		// Event listener for beforeunload event
		window.addEventListener("beforeunload", preventLeavingUnsavedChanges);

		return () => {
			window.removeEventListener("beforeunload", preventLeavingUnsavedChanges);
		};
	}, []);

	useEffect(() => {
		if (versions.length === 0) return;
		const nSaveEnabled =
			!policies.error &&
			!saving &&
			(info.editGameTitle !== v.title ||
				info.editMinPlayers !== v.min_players ||
				info.editMaxPlayers !== v.max_players ||
				info.editCoverImageURL !== v.thumbnail_url ||
				info.editFaviconURL !== v.mini_icon_url ||
				info.editDescription !== v.description ||
				assets.editUnityLoader !== null ||
				assets.editUnityData !== null ||
				assets.editUnityFramework !== null ||
				assets.editUnityCode !== null ||
				assets.editUnityJson !== null ||
				assets.editUnityZip !== null ||
				assets.editUnityMemory !== null ||
				info.editCoverImage !== null ||
				info.editFavicon !== null ||
				policies.modified ||
				media.filesToUpload.length > 0);
		setSaveEnabled(nSaveEnabled);
		saveEnabledRef.current = nSaveEnabled;
		setSubmitEnabled(
			!policies.error &&
				!saving &&
				v.title &&
				v.min_players &&
				v.max_players &&
				v.thumbnail_url &&
				v.mini_icon_url &&
				v.description &&
				v.unity_loader_url &&
				v.unity_data_url &&
				v.unity_framework_url &&
				v.unity_code_url &&
				v.unity_json_url &&
				v.unity_memory_url &&
				v.unity_zip_url &&
				media.filesToUpload.length === 0
		);
		if (v.unity_json_url && v.unity_json_url !== "") {
			setSubmitEnabled(
				!policies.error &&
					v.title &&
					v.min_players &&
					v.max_players &&
					v.thumbnail_url &&
					v.mini_icon_url &&
					v.description &&
					!saving &&
					v.unity_loader_url &&
					v.unity_json_url &&
					v.unity_zip_url &&
					media.filesToUpload.length === 0
			);
		}
		setTestEnabled(!saving && v.unity_loader_url && v.unity_data_url && v.unity_framework_url && v.unity_code_url);
		if (v.unity_json_url && v.unity_json_url !== "") {
			//we want to now edit the developer game type to be unity5 in supabase if there is a unity json file
			console.log("changing game type to unity5");
			handleChangeGameType(gameId, "unity5");
			setTestEnabled(!saving && v.unity_loader_url);
		}
		setWaitingForApproval(v.status === "Waiting for approval");
	}, [
		info.editGameTitle,
		info.editMinPlayers,
		info.editMaxPlayers,
		info.editCoverImageURL,
		info.editFaviconURL,
		info.editDescription,
		info.editCoverImage,
		info.editFavicon,
		info,
		assets.editUnityLoader,
		assets.editUnityData,
		assets.editUnityFramework,
		assets.editUnityCode,
		assets,
		v,
		versions,
		saving,
		policies,
		setWaitingForApproval,
		media,
		media.filesToUpload,
	]);

	const uploadStreamingAssetsToS3 = async (zipFile, body) => {
		const zip = new JSZip();
		const contents = await zip.loadAsync(zipFile);
		const uploadPromises = [];
		let baseUrl = "";

		for (const [relativePath, file] of Object.entries(contents.files)) {
			if (!file.dir) {
				const content = await file.async("blob");
				const fullPath = relativePath;
				const uploadPromise = saveToS3WithFileNameWithoutTimestamp(
					content,
					assets.setUnityZip,
					assets.setUnityZipURL,
					false,
					body,
					fullPath
				);
				uploadPromises.push(uploadPromise);
			}
		}

		if (!baseUrl) {
			baseUrl = uploadPromises[0].toString();
			baseUrl = baseUrl.split("/StreamingAssets")[0];
		}

		await Promise.all(uploadPromises);
		return baseUrl;
	};

	const modifyUnityJsonAndSaveToS3 = async (file, editUnityDataURL, editUnityFrameworkURL, editUnityCodeURL, editUnityMemoryURL, body) => {
		console.log("modifying unity json");
		console.log(file);
		console.log(editUnityDataURL);
		console.log(editUnityFrameworkURL);
		console.log(editUnityCodeURL);
		console.log(editUnityMemoryURL);

		// Return a promise to await FileReader's onload event
		return new Promise((resolve, reject) => {
			const reader = new FileReader();

			reader.onload = async (e) => {
				try {
					const text = e.target.result;
					console.log("text" + text);
					const json = JSON.parse(text);

					// Only modify the required URLs
					const updatedJson = {
						...json, // Keep all existing fields intact
						dataUrl: editUnityDataURL,
						asmFrameworkUrl: editUnityFrameworkURL,
						asmCodeUrl: editUnityCodeURL,
						asmMemoryUrl: editUnityMemoryURL,
					};

					// Convert the updated JSON back into a Blob
					const newJson = JSON.stringify(updatedJson, null, 2); // `null, 2` for pretty formatting
					console.log("updated json: " + newJson);
					const blob = new Blob([newJson], { type: "application/json" });

					// Save the updated JSON blob
					assets.setUnityJson(blob);
					console.log("updated json");

					// Save to S3 and resolve the promise when done
					const result = await saveToS3WithFileName(blob, assets.setUnityJson, assets.setUnityJsonURL, false, body, file.name);
					resolve(result);
				} catch (error) {
					console.error("Error processing JSON file:", error);
					reject(error);
				}
			};

			reader.onerror = (error) => {
				console.error("File reading error:", error);
				reject(error);
			};

			// Read the file as text
			reader.readAsText(file);
		});
	};

	const onSave = async () => {
		setSaving(true);
		savingRef.current = true;
		let editCoverImageURL = v.thumbnail_url;
		let editFaviconURL = v.mini_icon_url;
		let editUnityLoaderURL = v.unity_loader_url;
		let editUnityDataURL = v.unity_data_url;
		let editUnityFrameworkURL = v.unity_framework_url;
		let editUnityCodeURL = v.unity_code_url;
		let editUnityJsonURL = v.unity_json_url;
		let editUnityMemoryURL = v.unity_memory_url;
		let editUnityZipURL = v.unity_zip_url;
		const body = { gameId, version: intVersion };
		if (info.editCoverImage) {
			editCoverImageURL = await saveToS3(info.editCoverImage, info.setCoverImage, info.setCoverImageURL, true, body);
		}
		if (info.editFavicon) {
			editFaviconURL = await saveToS3(info.editFavicon, info.setFavicon, info.setFaviconURL, true, body);
		}
		if (assets.editUnityLoader) {
			editUnityLoaderURL = await saveToS3(assets.editUnityLoader, assets.setUnityLoader, assets.setUnityLoaderURL, false, body);
		}
		if (assets.editUnityData) {
			editUnityDataURL = await saveToS3(assets.editUnityData, assets.setUnityData, assets.setUnityDataURL, false, body);
		}
		if (assets.editUnityFramework) {
			editUnityFrameworkURL = await saveToS3(assets.editUnityFramework, assets.setUnityFramework, assets.setUnityFrameworkURL, false, body);
		}
		if (assets.editUnityCode) {
			editUnityCodeURL = await saveToS3(assets.editUnityCode, assets.setUnityCode, assets.setUnityCodeURL, false, body);
		}
		if (assets.editUnityMemory) {
			editUnityMemoryURL = await saveToS3(assets.editUnityMemory, assets.setUnityMemory, assets.setUnityMemoryURL, false, body);
		}
		if (assets.editUnityJson) {
			// the json file before uploading it
			editUnityJsonURL = await modifyUnityJsonAndSaveToS3(
				assets.editUnityJson,
				editUnityDataURL,
				editUnityFrameworkURL,
				editUnityCodeURL,
				editUnityMemoryURL,
				body
			);
			console.log("editUnityJsonURL: " + editUnityJsonURL);
		}
		if (assets.editUnityZip) {
			const folderUrl = await uploadStreamingAssetsToS3(assets.editUnityZip, body);
			console.log("folderUrl: " + folderUrl);
			editUnityZipURL = folderUrl;
		}
		if (media.filesToUpload.length > 0) {
			await media.uploadFiles(body);
		}
		let policiesUpdated = true;
		if (policies.modified) {
			policiesUpdated = await policies.updateGamePricing();
		}
		const nV = {
			title: info.editGameTitle,
			min_players: info.editMinPlayers,
			max_players: info.editMaxPlayers,
			thumbnail_url: editCoverImageURL,
			mini_icon_url: editFaviconURL,
			description: info.editDescription,
			unity_loader_url: editUnityLoaderURL,
			unity_data_url: editUnityDataURL,
			unity_framework_url: editUnityFrameworkURL,
			unity_code_url: editUnityCodeURL,
			unity_json_url: editUnityJsonURL,
			unity_memory_url: editUnityMemoryURL,
		};
		await updateVersion(nV, intVersion);

		setSaving(false);
		savingRef.current = false;
	};

	return { saveEnabled, submitEnabled, testEnabled, saving, onSave };
};

export default useSaveSubmit;
