export function getContentType(fileName) {
	if (fileName.endsWith(".png")) {
		return "image/png";
	}
	if (fileName.endsWith(".jpg")) {
		return "image/jpeg";
	}
	if (fileName.endsWith(".jpeg")) {
		return "image/jpeg";
	}
	if (fileName.endsWith(".js")) {
		return "application/javascript";
	}
	if (fileName.endsWith(".data")) {
		return "application/octet-stream";
	}
	if (fileName.endsWith(".wasm")) {
		return "application/wasm";
	}
}

export const getFileNameFromURL = (url) => {
	const parts = url.split("/");
	const fullName = parts[parts.length - 1];
	const removedTimestamp = fullName.split("-").slice(1).join("-");
	if (removedTimestamp) {
		return removedTimestamp;
	}
	return fullName;
};

export const saveToS3 = async (file, setFile, setUrl, isImage, additionalBody) => {
	const { url, cdnAccessUrl } = await fetch("/api/s3Url", {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify({
			fileName: file.name,
			...additionalBody,
		}),
	}).then((res) => res.json());

	await fetch(url, {
		method: "PUT",
		headers: {
			"Content-Type": getContentType(file.name),
		},
		body: file,
	});

	setUrl(cdnAccessUrl);
	setFile(null);
	return cdnAccessUrl;
};

export const saveToS3WithFileNameWithoutTimestamp = async (file, setFile, setUrl, isImage, additionalBody, fileName) => {
	const { url, cdnAccessUrl } = await fetch("/api/s3UrlWithoutTimestamp", {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify({
			fileName: fileName,
			...additionalBody,
		}),
	}).then((res) => res.json());

	await fetch(url, {
		method: "PUT",
		headers: {
			"Content-Type": getContentType(fileName),
		},
		body: file,
	});

	setUrl(cdnAccessUrl);
	setFile(null);
	return cdnAccessUrl;
};

export const saveToS3WithFileName = async (file, setFile, setUrl, isImage, additionalBody, fileName) => {
	const { url, cdnAccessUrl } = await fetch("/api/s3Url", {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify({
			fileName: fileName,
			...additionalBody,
		}),
	}).then((res) => res.json());

	await fetch(url, {
		method: "PUT",
		headers: {
			"Content-Type": getContentType(fileName),
		},
		body: file,
	});

	setUrl(cdnAccessUrl);

	setFile(null);
	return cdnAccessUrl;
};
